import { ROLES } from '../constants'

export type RoleT = (typeof ROLES)[keyof typeof ROLES]

export type Alias = {
  aff: string
  email: string
  name: {
    givenNames: string
    surname: string
  }
}

export type Author = {
  alias: Alias
  affiliationNumber: number

  id: string
  isSubmitting: boolean
  isCorresponding: boolean
}

export type AcademicEditor = {
  id: string
  workload: number
  score: number
  user: {
    id: string
  }
  conflictsOfInterest: {
    id: string
    alias: Alias
  }
}
export type PublisherName = 'WILEY' | 'HINDAWI'

export type PeerReviewModel = {
  id: string
  name: string
}

export type ArticleType = {
  id: string
  name: string
}

export type Identity = {
  __typename: string
  email: string
  name: {
    givenNames: string
    surname: string
  }
}

export type WaiverApplied = {
  type: WaiverType
  reason: string | null
  otherReason: string | null
} | null

export enum WaiverType {
  WAIVER_CODE = 'waiverCode',
  APC_OVERRIDE = 'apcOverride',
}
